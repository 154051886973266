import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { Link } from "../components/CustomLinks"
import Layout from "../components/layout"
import ColoredHeader from "../components/coloredHeader"
import drew_coffman_blackwhite from "../../static/background_images/drew_coffman_blackwhite.jpg"

import siteContent from "../siteContent"

export default function SearchPage(props) {
  const [results, setResults] = useState([])
  const [searched, setSearched] = useState(false)
  const [searchQuery, setSearchQuery] = useState("")
  const [searchTimeout, setSearchTimeout] = useState({
    query: "",
    typingTimeout: 0,
  })

  const searchFromQuery = query => {
    const individualQueries = query.toLowerCase().split(" ")
    const results = []
    Object.keys(siteContent).forEach(key => {
      const found = individualQueries.every(word => {
        console.log({word, key: siteContent[key]})
        return siteContent[key].content.toLowerCase().includes(word)
      })
      if (found) {
        results.push(
          <Link className="result-link" to={key} key={key}>
            {siteContent[key].displayName}
          </Link>
        )
      }
    })
    setSearched(true)
    setResults(results)
  }

  useEffect(() => {
    if (!searchQuery) return
    searchFromQuery(searchQuery)
  }, [searchQuery])

  useEffect(() => {
    const queryString = window.location.search
    const query = new URLSearchParams(queryString).get("query")
    if (!query) return
    setSearchTimeout({
      query,
      typingTimeout: setTimeout(function() {
        setSearchQuery(query.toLowerCase())
      }, 0),
    })
  }, [])

  const waitForChanges = (event, timeout = 1000) => {
    if (searchTimeout.typingTimeout) {
      clearTimeout(searchTimeout.typingTimeout)
    }
    let query = event.target.value
    setSearchTimeout({
      query,
      typingTimeout: setTimeout(function() {
        setSearchQuery(query.toLowerCase())
      }, timeout),
    })
  }

  return (
    <Layout>
      <Helmet>
        <title>Search – EOG Resources, Inc.</title>
      </Helmet>
      <ColoredHeader
        title="EOG RESOURCES"
        header="Search Result"
        overlayColor1="#54595f"
        overlayColor2="#8e8e8e"
        lineColor="#ffffff"
        bgImageSRC={drew_coffman_blackwhite}
      />
      <div className="page search-page last-page max-width-container">
        <div className="search-wrapper">
          <input
            className="search-input"
            type="text"
            value={searchTimeout.query}
            onChange={e => waitForChanges(e)}
            onKeyUp={e => {
              if (e.keyCode === 13) {
                waitForChanges(e, 0)
              }
            }}
          />
          <FontAwesomeIcon icon={faSearch} />
        </div>
        {searched && (
          <>
            <ul className="search-tags">
              <li className="tag">page</li>
            </ul>
            <div className="searchresultcount">
              {results.length} Results Found
            </div>
            {results.map(elem => {
              return elem
            })}
          </>
        )}
      </div>
    </Layout>
  )
}
